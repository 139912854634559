import IDE from './components/IDE';
import './App.css';

function App() {
  return (
    <div>
      <IDE></IDE>
    </div>
  );
}

export default App;